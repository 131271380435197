import { Override } from "framer"
import { motion, useMotionValue, useSpring } from "framer-motion"
import { useEffect, useRef, useCallback } from "react"

export function Followcursor({
    damping = 100,
    stiffness = 1000,
    effectRange = 2,
    intensity = 5,
    actionableArea = 30,
    style,
}: {
    damping?: number
    stiffness?: number
    effectRange?: number
    intensity?: number
    actionableArea?: number
    style: any
}): Override {
    const x = useMotionValue(0)
    const y = useMotionValue(0)
    const springX = useSpring(x, { damping, stiffness })
    const springY = useSpring(y, { damping, stiffness })
    const ref = useRef<HTMLDivElement>(null)

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            if (!ref.current) return
            const element = ref.current as HTMLElement
            const rect = element.getBoundingClientRect()
            const mouseX = event.clientX
            const mouseY = event.clientY

            const isWithinBounds =
                mouseX >= rect.left - actionableArea &&
                mouseX <= rect.right + actionableArea &&
                mouseY >= rect.top - actionableArea &&
                mouseY <= rect.bottom + actionableArea

            if (isWithinBounds) {
                const displacementX = mouseX - (rect.left + rect.width / 2)
                const displacementY = mouseY - (rect.top + rect.height / 2)
                const normalizedX =
                    (displacementX / (rect.width / 2)) * effectRange * intensity
                const normalizedY =
                    (displacementY / (rect.height / 2)) *
                    effectRange *
                    intensity

                // Use requestAnimationFrame to update motion values
                requestAnimationFrame(() => {
                    x.set(normalizedX)
                    y.set(normalizedY)
                })
            } else {
                // Reset position if mouse is out of bounds
                requestAnimationFrame(() => {
                    x.set(0)
                    y.set(0)
                })
            }
        },
        [actionableArea, effectRange, intensity, x, y]
    )

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove)
        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [handleMouseMove])

    return {
        ref: ref,
        style: {
            x: springX,
            y: springY,
            ...style,
        },
    }
}
